import classNames from "classnames";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ButtonSquare from "~/components/ButtonSquare";
import BuyButton from "~/components/BuyButton";
import MediaFull from "~/components/MediaFull";
import Price from "~/components/Price";
import Spacer from "~/components/Spacer";
import { Text } from "~/components/Text";
import Title from "~/components/Title";
import { imageUrl } from "~/external/commercetools";
import useFreeShipping from "~/hooks/commercetools/useFreeShipping";
import useCountry from "~/hooks/useCountry";
import { ThemeContext } from "~/lib/ThemeContext";
import { setBuyButtonVisible } from "~/store/ui";
import { paragraph, ul } from "~/styles/mixins";
import theme, { biggerThan, px, rem, smallerThan } from "~/styles/theme";
import Hero3D from "./Hero3D";

const BuyWrapperBig = styled.div`
  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    /* display: none; */
    position: absolute;
    pointer-events: none;
    opacity: 0;
    /* visibility: none; */
  }
`;

const BuyWrapperSmall = styled.div`
  background-color: ${(p) => p.theme.colors.light};
  z-index: ${(p) => p.theme.z.topBar - 10};
  /* position: ${(p) => p.position || "fixed"}; */
  position: fixed;
  bottom: 0;
  /* left: ${(p) =>
    p.position === "absolute" ? -p.theme.sizes.hSpacingSmall : "0"}rem;
  right: ${(p) =>
    p.position === "absolute" ? -p.theme.sizes.hSpacingSmall : "0"}rem; */
  left: 0;
  right: 0;
  button {
    width: 100%;
  }
  > div {
    text-align: center;
  }
  ${(p) => biggerThan(p.theme.breakpoints.ipadPortrait)} {
    display: none;
  }
`;

const MediaWrapper = styled.div`
  position: relative;
  z-index: ${(p) => p.theme.z.body};
  margin: 0 auto;
  display: block;
  width: 56vw;
  /* padding-top: 4vw; */
  /* height: 60vw; */
  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    width: 90vw;
    /* height: auto; */
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Main = styled.div`
  position: relative;
  color: ${(p) => p.color};
`;

const Info = styled.div`
  position: absolute;
  z-index: ${(p) => p.theme.z.body + 1};
  top: 0;
  left: 0;
  width: 28vw;

  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }

  h4 {
    /* font-size: 16px; */
    font-size: ${rem("16px")};
    font-weight: bold;
    margin-bottom: ${(p) => p.theme.sizes.vSpacing}rem;
  }
  /* p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 45px;
  } */
  h3 {
    font-size: ${rem("24px")};
    font-weight: bold;
  }
  button {
  }

  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    position: relative;
    width: 100%;
  }
`;

const TermsWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TermsSpacer = styled.div`
  height: 100%;
`;

const BigTerms = styled.ul`
  pointer-events: all;
  /* position: sticky; */
  position: absolute;
  /* top: 100vh; */
  left: 0;
  bottom: ${(p) => p.theme.sizes.vSpacing * 2}rem;
  /* transform: translateY(-100%); */
  /* padding: ${(p) => p.theme.sizes.vSpacing}rem ${(p) =>
    p.theme.sizes.hSpacing}rem; */
  margin: 0;
  padding: 0;
  /* padding-bottom: ${(p) => p.theme.sizes.vSpacing * 2}rem; */
  font-size: ${rem("13px")};
  line-height: 1.7;
  /* font-family: 'RelativeFaux'; */
  font-family: "AIAIAI";
  list-style: none;

  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    display: none;
  }
`;

const AvailableText = styled.div`
  font-size: ${rem("13px")};
  opacity: 0.8;
`;

const SmallTerms = styled.div`
  font-size: ${rem("13px")};
  line-height: 2.7;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
  /* font-family: 'RelativeFaux'; */
`;

const TextWrapper = styled.div`
  p {
    ${paragraph};
    white-space: pre-line;
    margin: ${(p) => p.theme.sizes.vSpacing}rem 0;
    ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
      margin-top: ${(p) => p.theme.sizes.vSpacingSmall * 4}rem;
    }
  }
  ul {
    ${ul};
    /* margin-left: 0;
    padding-left: 1.3rem; */
  }
`;

const getVariantColor = (name) => {
  const color = theme.variantColors[name];
  return color || "#272727";
};

const VariantColor = styled.div`
  position: relative;
  width: ${rem("18px")};
  height: ${rem("18px")};
  background-color: ${(p) => getVariantColor(p.variantColor)};
  border-radius: ${rem("9px")};
  display: inline-block;
  margin-right: ${(p) => p.theme.sizes.hSpacing}rem;
  cursor: pointer;
  &:hover,
  &.selected {
    &:after {
      content: "";
      position: absolute;
      width: ${rem("4px")};
      height: ${rem("4px")};
      background-color: ${(p) => p.theme.colors.primary};
      border-radius: ${rem("2px")};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const VariantSize = styled.div`
  position: relative;
  padding: ${rem("1px")} 0 0 ${rem("18px")};
  margin-bottom: ${(p) => p.theme.sizes.vSpacing}rem;
  width: ${rem("18px")};
  height: ${rem("18px")};
  line-height: ${rem("18px")};
  background-color: ${(p) => getVariantColor(p.variantColor)};
  border-radius: ${rem("9px")};
  display: block;
  margin-right: ${(p) => p.theme.sizes.hSpacing}rem;
  cursor: pointer;
  &:hover,
  &.selected {
    &:after {
      content: "";
      position: absolute;
      width: ${rem("4px")};
      height: ${rem("4px")};
      background-color: ${(p) => p.theme.colors.primary};
      border-radius: ${rem("2px")};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  span {
    margin-left: ${rem("8px")};
    white-space: nowrap;
  }
`;

// remove product key from variant key
// eg. "tracks-black" = "black"
// const resolveVariantSlug = (variantKey, productKey) =>
//   variantKey.replace(productKey + '-', '');

function ProductHero(props) {
  const currentTheme = useContext(ThemeContext);

  let {
    titleType,
    titleLine1,
    titleLine2,
    titleLine3,
    titleFontStyle,
    titleFontWeight,
    highlightColor = currentTheme.highlight,
    highlightOutline,
    color = currentTheme.foreground,
    subtitle,
    subtitleFontStyle,
    subtitleFontWeight,
    text,
    fontStyle,
    fontWeight,
    product = null,
    productAlternative = null,
    variantKey,
    media,
    atlas,
    atlasFallback,
    customOutOfStockText, // FIX
  } = props;

  // console.log('productAlternative', variantKey, productAlternative);
  // console.log('pr', product.variants[0]);

  const router = useRouter();
  const freeShipping = useFreeShipping();
  const country = useCountry();
  const dispatch = useDispatch();

  const [selectedProduct, setSelectedProduct] = useState(productAlternative ?? product);
  useEffect(() => {
    setSelectedProduct(productAlternative ?? product);
  }, [productAlternative, product]);

  function gotoVariant(variantSlug, replace = false) {
    // Quickfix for when not on /headphones/[...slug], e.g. /pages/tracks
    if (router.pathname.indexOf("[...slug]") !== -1) {
      const { slug } = router.query;
      slug[1] = variantSlug;
      const href = router.pathname;
      const hrefAs = router.pathname.replace("[...slug]", slug.join("/"));

      if (replace) {
        router.replace(href, hrefAs);
      } else {
        router.push(href, hrefAs);
      }
    }
  }

  // find the index of the selected variant
  let variantIndex = 0;
  if (product && variantKey) {
    variantIndex = selectedProduct.variants.findIndex((v) => v.key === variantKey);
    if (variantIndex === -1) variantIndex = product.masterVariantIndex;
  }

  // console.log("variantKey ?? ", variantKey);

  const variant = selectedProduct ? selectedProduct.variants[variantIndex] : null;
  if (!variantKey) {
    variantKey = variant.key;
  }

  // Overwrite the selection if we ended up on the wrong page (e.g. we loaded /eu in the us)
  useEffect(() => {
    const matchingVariants =
      country &&
      product &&
      product.variants.length > 1 &&
      product.variants.some((v) => !!v.powerPlugType)
        ? product.variants.filter(
            (v) =>
              !!country.powerPlugType &&
              country.powerPlugType.toLowerCase() === v.powerPlugType.key.toLowerCase()
          )
        : [];
    if (
      variant &&
      matchingVariants.length > 0 &&
      !matchingVariants.map((v) => v.key).includes(variant.key)
    ) {
      gotoVariant(matchingVariants[0].powerPlugType.key, true);
    }
  });

  // check if button is in view
  // we want to let the store.ui know if the button is visible
  const [buttonRef, buttonInView] = useInView({
    // when it goes "behind" the top bar...
    rootMargin: `-${px(theme.sizes.topBarHeight.toString())} 0px 0px 0px`, // (top, right, bottom, left).
  });
  useEffect(() => {
    dispatch(setBuyButtonVisible(buttonInView));
    return () => {
      // rememeber to remove this?
      dispatch(setBuyButtonVisible(false));
    };
  }, [buttonInView, dispatch]);

  if (!titleLine1 && product) {
    titleLine1 = <p>{product.name.toUpperCase()}</p>;
  }

  if (!text && product) {
    text = <p>{product.description}</p>;
  }

  if (!media && product) {
    if (variant.images.length > 0) {
      if (variant.images[0].url) {
        media = [
          imageUrl(variant.images[0].url, "large"),
          "image",
          variant.images[0].dimensions.width,
          variant.images[0].dimensions.height,
        ];
      }
    }
  }

  // console.log('variant.availability.expectedDelivery', variant.availability.expectedDelivery);

  return (
    <>
      <Wrapper>
        {titleLine1 && (
          <Title
            type={titleType}
            line1={titleLine1}
            line2={titleLine2}
            line3={titleLine3}
            fontStyle={titleFontStyle}
            fontWeight={titleFontWeight}
            highlightColor={highlightColor}
            highlightOutline={highlightOutline}
            color={color}
          />
        )}
        <Main color={color}>
          {/* {media && getMedia(media)} */}
          <MediaWrapper>
            {atlas ? (
              // <Image3D backgroundColor="transparent" src={atlas} />
              <Hero3D backgroundColor="transparent" src={atlas} />
            ) : (
              <MediaFull backgroundColor="transparent" media={media} noPadding />
            )}
          </MediaWrapper>
          <Info>
            {subtitle && (
              <Text
                as="h4"
                fontStyle={subtitleFontStyle}
                fontWeight={subtitleFontWeight}
                color={color}
              >
                {subtitle}
              </Text>
            )}

            {text && (
              <Text fontStyle={fontStyle} fontWeight={fontWeight} color={color}>
                <TextWrapper>{text}</TextWrapper>
              </Text>
            )}

            <Spacer backgroundColor="transparent" size={3} />

            {product &&
              product.variants.length > 1 &&
              product.variants.some((v) => !!v.powerPlugType) &&
              product.variants.filter(
                (variant) =>
                  !country ||
                  !country.powerPlugType ||
                  country.powerPlugType.toLowerCase() ===
                    variant.powerPlugType.key.toLowerCase()
              ).length > 1 && (
                <>
                  <h4>Power plug type</h4>
                  {product.variants
                    .filter(
                      (variant) =>
                        !country ||
                        !country.powerPlugType ||
                        country.powerPlugType.toLowerCase() ===
                          variant.powerPlugType.key.toLowerCase()
                    )
                    .map((variant, i) => (
                      <VariantSize
                        key={i}
                        onClick={() => gotoVariant(variant.powerPlugType.key)}
                        title={variant.powerPlugType.label}
                        alt={variant.powerPlugType.label}
                        className={classNames({
                          selected: variant.key === variantKey,
                        })}
                      >
                        <span>{variant.powerPlugType.label}</span>
                      </VariantSize>
                    ))}
                  <Spacer backgroundColor="transparent" size={3} />
                </>
              )}

            {product &&
              product.variants.length > 1 &&
              product.variants.some((v) => !!v.color) && (
                <>
                  <h4>Color</h4>
                  {product.variants.map((variant, i) => (
                    <VariantColor
                      key={i}
                      onClick={() => gotoVariant(variant.color.slug)}
                      variantColor={variant.color.slug}
                      title={variant.color.name}
                      alt={variant.color.name}
                      className={classNames({
                        selected: variant.key === variantKey,
                      })}
                    />
                  ))}
                  <Spacer backgroundColor="transparent" size={3} />
                </>
              )}

            <Spacer backgroundColor="transparent" size={3} />

            {product &&
              product.variants.length > 1 &&
              product.variants.some((v) => !!v.size) && (
                <>
                  <h4>Size</h4>
                  {product.variants.map((variant, i) => (
                    <VariantSize
                      key={i}
                      onClick={() => gotoVariant(variant.size.key.replace("size-", ""))}
                      // variantColor={variant.size.key}
                      title={variant.size.label}
                      alt={variant.size.label}
                      className={classNames({
                        selected: variant.key === variantKey,
                      })}
                    >
                      <span>{variant.size.label}</span>
                    </VariantSize>
                  ))}
                  <Spacer backgroundColor="transparent" size={3} />
                </>
              )}

            {product &&
              product.variants.length > 1 &&
              product.variants.some((v) => !!v.connector) && (
                <>
                  <h4>Connector</h4>
                  {product.variants.map((variant, i) => (
                    <VariantSize
                      key={i}
                      onClick={() => gotoVariant(variant.connector.key)}
                      // variantColor={variant.size.key}
                      title={variant.connector.label}
                      alt={variant.connector.label}
                      className={classNames({
                        selected: variant.key === variantKey,
                      })}
                    >
                      <span>{variant.connector.label}</span>
                    </VariantSize>
                  ))}
                  <Spacer backgroundColor="transparent" size={3} />
                </>
              )}

            {productAlternative && (
              <>
                <h4>Choose your headband</h4>
                {[product, productAlternative].map((product, i) => {
                  const selectedVariant =
                    selectedProduct.variants[selectedProduct.masterVariantIndex];
                  const variant = product.variants[product.masterVariantIndex];
                  const headband = variant.bundledProducts.find(
                    (bp) => bp.typeKey === "headband"
                  );
                  const description =
                    headband.variants[headband.masterVariantIndex]?.attributes[
                      "alternative-description"
                    ]?.["en-US"] ?? "N/A";
                  const priceDiff =
                    variant.price.value.centAmount -
                    selectedVariant.price.value.centAmount;
                  return (
                    <VariantSize
                      key={i}
                      onClick={() => setSelectedProduct(product)}
                      // variantColor={variant.size.key}
                      title={headband?.description}
                      alt={variant.name}
                      className={classNames({
                        selected: variant.key === selectedVariant.key,
                      })}
                    >
                      <span>
                        {headband && (
                          <>
                            {description}{" "}
                            {priceDiff !== 0 && (
                              <Price
                                {...variant.price.value}
                                centAmount={priceDiff}
                                currencyStyle={{ display: "none" }}
                                showPositiveSign
                              />
                            )}
                          </>
                        )}
                      </span>
                    </VariantSize>
                  );
                })}
                <Spacer backgroundColor="transparent" size={3} />
              </>
            )}

            {variant && (
              <BuyWrapperBig>
                {variant && (
                  <>
                    {/* <PriceLine price={variant.price} /> */}
                    {!variant.price.discounted ? (
                      <h3>
                        <span>
                          {variant.price.value.currencyCode}{" "}
                          {variant.price.value.centAmount / 100}
                        </span>
                      </h3>
                    ) : (
                      <>
                        <h3>
                          <span style={{ color: theme.colors.discount }}>
                            {variant.price.discounted.value.currencyCode}{" "}
                            {variant.price.discounted.value.centAmount / 100}
                          </span>
                        </h3>
                        <h3 style={{ marginTop: "-10px" }}>
                          <span style={{ opacity: 0.7, fontSize: "18px" }}>
                            <strike>
                              {variant.price.value.currencyCode}{" "}
                              {variant.price.value.centAmount / 100}
                            </strike>
                          </span>
                        </h3>
                      </>
                    )}
                  </>
                )}
                <Spacer backgroundColor="transparent" size={3} />

                <BuyButton
                  variant={variant}
                  buttonComponent={ButtonSquare}
                  includePrice={false}
                  customOutOfStockText={customOutOfStockText}
                  id="buy_button"
                  color={color}
                  highlightColor={currentTheme.highlight}
                  disabled={
                    variant.productTypeKey !== "parts-bundle" &&
                    variant.availability.availableQuantity === 0
                  }
                  allowNotifyMe
                />
                <Spacer backgroundColor="transparent" size={1} />

                {/* {variant.availability === 0 && variant.availability.expectedDelivery && ( */}
                {variant.availability.availableQuantity === 0 && (
                  <AvailableText>
                    {variant.availability.expectedDelivery !== null &&
                      !isNaN(variant.availability.expectedDelivery) &&
                      (new Date(variant.availability.expectedDelivery) > new Date() ? (
                        <>
                          Available{" "}
                          {new Date(
                            variant.availability.expectedDelivery
                          ).toDateString()}
                        </>
                      ) : (
                        <>Coming soon!</>
                      ))}
                  </AvailableText>
                )}
              </BuyWrapperBig>
            )}
          </Info>

          <BigTerms>
            {freeShipping && (
              <li className="shipping-price">
                FREE shipping
                {variant.price.value.centAmount < freeShipping.centAmount && (
                  <>
                    {" "}
                    above {freeShipping.centAmount / 100} {freeShipping.currencyCode}
                  </>
                )}
              </li>
            )}
            <li className="shipping-return">30 days returns policy</li>
            <li className="shipping-warranty">2 years warranty</li>
          </BigTerms>
          {/* <FakeMobileBuyButton id="buy_button">fake</FakeMobileBuyButton> */}
        </Main>
        {/* <TermsWrapper>
        <TermsSpacer />

      </TermsWrapper> */}
      </Wrapper>
      <BuyWrapperSmall className="hide-if-fullscreen">
        <BuyButton
          variant={variant}
          buttonComponent={ButtonSquare}
          includePrice
          customOutOfStockText={customOutOfStockText}
          color={color}
          id="buy_button"
          allowNotifyMe
        />

        <SmallTerms color={color} backgroundColor={currentTheme.background}>
          {variant.availability.availableQuantity === 0 &&
          variant.availability.expectedDelivery !== null &&
          !isNaN(variant.availability.expectedDelivery) &&
          new Date(variant.availability.expectedDelivery) > new Date() ? (
            <>
              Available {new Date(variant.availability.expectedDelivery).toDateString()}
            </>
          ) : (
            <>30 days returns policy</>
          )}
        </SmallTerms>
      </BuyWrapperSmall>
    </>
  );
}

export default ProductHero;
